import React from "react"

import { Link, useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionImage from "../components/section-image"

const AboutEnPage = () => {

  const data = useStaticQuery(graphql`
    query {
      about: file(relativePath: { eq: "port.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      parallax: file(relativePath: { eq: "carousel/hero.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
              ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return(

    <Layout en={true}>
        <SEO title="About us" />
        <section className="container-md py-5">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              {/* <h6 className="tag h5 pb-4">Experience and Relationships</h6> */}
              <h1 className="h1 text-dark mb-5">Pioneer solution’s network covers the entire Americas, Greater China, and extensively in Southeast Asia.</h1>
            </div>

            <div className="col-sm-12 col-lg-6 d-flex flex-column align-items-start justify-content-start">
              <Img fluid={data.about.childImageSharp.fluid} className="w-100 rounded mb-5" alt="Shipping" />
              <h2 className="h5 text-dark pb-3">Pioneer Solutions enjoys unparalleled access and relationships with the major steamship lines and cargo airlines.</h2>
              <p className="pb-3">Founded in 2016 by a team of logistics professionals with a true passion for international trade, Pioneer Solution’s mission has always been to be the preferred freight forwarder serving and facilitating the logistics needs of other entrepreneurs and helping them realize their business’s full potential on a global scale.</p>
              <p className="pb-3">Our expertise in connecting Trans-Pacific trade has enabled us to grow together with our customers. Headquartered in Los Angeles but with a strong global vision, Pioneer Solutions is a leading integrated logistics company comprising of four business segments: NVOCC, Warehousing & Distribution, Customs Clearance & Trade Advisory, and Trucking.</p>
            </div>
          </div>
        </section>

        <Img fluid={data.parallax.childImageSharp.fluid} className="bg-parallax h-200" alt="Pioneer solutions" />
        
        <section className="container-md py-5">
          <h2 className="text-dark pb-5 text-center text-center">Team of logistics professionals</h2>

          <div className="row">
            <div className="col-sm-12 col-md-4 pb-5">
              <h6 className="tag pb-3">Experience and Relationships</h6>
              <p className="mb-0">With a combined 30 years’ experience, Pioneer Solutions has built a deep relationship with our customers and transportation partners; our superior access to top management and real-time communications enables us to provide our customers with tailored solutions at the lowest cost.</p>
            </div>

            <div className="col-sm-12 col-md-4 pb-5"> 
              <h6 className="tag pb-3">Mission Statement</h6>
              <p className="mb-0">Our mission is to provide solutions to our clients while offering exceptional service and setting the standard for professionalism in the logistics industry.</p>
            </div>

            <div className="col-sm-12 col-md-4 pb-5 d-flex flex-column align-items-start justify-content-start">
              <h6 className="tag pb-3">The Pioneer Difference</h6>
              <p className="mb-0">At Pioneer Solutions we treat all shipments with importance and urgency, we take a personal approach to our customers’ success and aim to be their trusted logistics partner. We remain committed to our founding philosophy of superior customer service and putting our customers first.</p>
            </div>
          </div>
        </section>
        
        <div className="border-top bg-info"> 
          <section className="container-md py-5 rounded">
            <div className="d-flex flex-column align-items-center">
              <h2 className="text-white h3 pb-3 text-center text-center">Pioneer Solutions is a trusted importer</h2>
              {/* <p className="text-center text-white pb-4">Founded in 2016 by a team of logistics professionals with a true passion for international trade, <b>Pioneer Solution’s mission has always been to be the preferred freight forwarder serving and facilitating the logistics needs</b> of other entrepreneurs and <b>helping them realize their business’s full potential on a global scale</b>.</p> */}
              <Link to="/contact-us" className="btn btn-light rounded">Contact us now</Link>
            </div>
          </section>
        </div>
    </Layout>
  )
}

export default AboutEnPage
